<template>
  <div style="padding:0;">
    <active-tab :activeTab="activeTab"/>
    <div style="padding:50px; padding-top:30px;">
      <div
        class="d-flex justify-content-start cursor-pointer back mb-4"
        @click="back" style="color: var(--primary) !important;"
      >
        <span class="material-symbols-rounded" style="margin: 0;"
          >arrow_back</span
        >
        <span style="margin: 0 0 0 8px">Back</span>
      </div>
      <div class="row">
        <div class="col col-md-9 col-lg-9 col-xl-9 d-flex align-items-center">
          <h5 class="font-weight-bold">New Client Registration Form</h5>
        </div>
  
        <div class="col-6 col-md-3 col-lg-3 col-xl-3 d-flex justify-content-end">
          <button @click="addclient" class="add-button mb-2">
            {{ "Add Client" }}
          </button>
        </div>
      </div>
  
      <div
        class="card border-0 shadow mt-3 p-3"
        :style="{ 'min-height': isDropdownOpen ? '480px' : 'auto' }"
      >
        <h5 class="mb-3 font-weight-bold td-underline">Basic Details</h5>
  
        <div class="row">
          <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <div class="row">
              <div class="card-text my-1">
                <span for="fname" class="font-weight-bold my-2"
                  >Manager Name</span
                >
              </div>
  
              <div class="card-text my-1">
                <b-form-input
                  autocorrect="off"
                  autocomplete="off"
                  v-model="manager_name"
                  type="text"
                  id="manager_name"
                  name="manager_name"
                  placeholder="Manager Name"
                  v-validate="'required'"
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('manager_name')"
                  >{{ errors.first("manager_name") }}</span
                >
              </div>
            </div>
  
            <div class="row mt-3">
              <div class="card-text my-1">
                <span for="fname" class="font-weight-bold my-2">Password</span>
              </div>
  
              <div class="card-text my-1">
                <b-form-input
                  autocorrect="off"
                  autocomplete="off"
                  v-model="manager_password"
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Password"
                  v-validate="'required'"
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('password')"
                  >{{ errors.first("password") }}</span
                >
              </div>
            </div>
  
            <div class="row mt-4">
              <div class="card-text my-1">
                <span for="fname" class="font-weight-bold my-2">Expiry Date</span>
              </div>
  
              <div class="card-text my-1">
                <b-form-datepicker
                  id="example-datepicker"
                  v-model="expiry_date"
                  calendar-width="100%"
                  :min="minDate"
                  placeholder="Choose a Date"
                ></b-form-datepicker>
              </div>
            </div>
          </div>
  
          <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <div class="row">
              <div class="card-text my-1">
                <span for="fname" class="font-weight-bold my-2">Email</span>
              </div>
  
              <div class="card-text my-1">
                <b-form-input
                  autocorrect="off"
                  autocomplete="off"
                  v-model="manager_email"
                  type="email"
                  id="manager_email"
                  name="manager email"
                  placeholder="Email"
                  v-validate="'required'"
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('manager email')"
                  >{{ errors.first("manager email") }}</span
                >
              </div>
            </div>
  
            <div class="row mt-3">
              <div class="card-text my-1">
                <span for="fname" class="font-weight-bold my-2"
                  >Contact Number</span
                >
              </div>
  
              <div class="card-text my-1">
                <b-form-input
                  autocorrect="off"
                  autocomplete="off"
                  v-model="extra_info.contact_number"
                  type="text"
                  id="contact_number"
                  name="contact number"
                  placeholder="Contact Number"
                  v-validate="'required'"
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('contact number')"
                  >{{ errors.first("contact number") }}</span
                >
              </div>
            </div>
  
            <div class="row mt-4">
              <div class="card-text my-1">
                <span for="fname" class="font-weight-bold my-2"
                  >Company Name</span
                >
              </div>
  
              <div class="card-text my-1">
                <b-form-input
                  autocorrect="off"
                  autocomplete="off"
                  v-model="extra_info.company_name"
                  type="text"
                  id="company_name"
                  name="company name"
                  placeholder="Company Name"
                  v-validate="'required'"
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('company name')"
                  >{{ errors.first("company name") }}</span
                >
              </div>
            </div>
          </div>
  
          <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <div class="row">
              <div class="card-text my-1">
                <span for="fname" class="font-weight-bold my-2">Address</span>
              </div>
  
              <div class="card-text my-1">
                <b-form-input
                  autocorrect="off"
                  autocomplete="off"
                  v-model="extra_info.address_1"
                  placeholder="Address Line 1"
                  type="text"
                  id="addressline1"
                  name="address line 1"
                  v-validate="'required'"
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('address line 1')"
                  >{{ errors.first("address line 1") }}</span
                >
  
                <b-form-input
                  class="input-address"
                  autocorrect="off"
                  autocomplete="off"
                  v-model="extra_info.address_2"
                  placeholder="Address Line 2"
                  type="text"
                  id="addressline2"
                  name="address line 2"
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('address line 2')"
                  >{{ errors.first("address line 2") }}</span
                >
  
                <b-form-input
                  class="input-address"
                  autocorrect="off"
                  autocomplete="off"
                  v-model="extra_info.address_3"
                  placeholder="Address Line 3"
                  type="text"
                  id="addressline3"
                  name="address line 3"
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('address line 3')"
                  >{{ errors.first("address_3") }}</span
                >
              </div>
            </div>
  
            <div class="row">
              <div class="card-text">
                <span for="fname" class="font-weight-bold my-2">Label(s)</span>
              </div>
  
              <div class="row my-2">
                <div class="col-12 col-md-12 col-xl-12">
                  <div class="row">
                    <div
                      id="clientAddLabel"
                      class="col-10 col-md-10 col-lg-11 align-self-center mb-2"
                    >
                      <multiselect
                        name="label"
                        v-validate="'required'"
                        maxHeight="122"
                        optionHeight="20"
                        v-model="itemsList"
                        :options="labelsList"
                        placeholder="Choose Label(s)"
                        @select="AddData"
                        @remove="removeData"
                        :multiple="true"
                        :close-on-select="false"
                        :searchable="false"
                        selectLabel=""
                        deselectLabel="Click to remove"
                        :limit="2"
                        @open="onDropdownOpen"
                        @close="onDropdownClose"
                      ></multiselect>
                    </div>
  
                    <div class="col-10 align-self-center">
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('label')"
                        >{{ errors.first("label") }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
  
              <!-- <div class="row" :key="index" v-for="(item, index) in labels">
                <div class="col-12 col-md-12 col-xl-12">
                  <div class="row">
                    <div
                      class="col-10 col-md-10 col-lg-11 align-self-center mb-2"
                    >
                      <b-form-input
                        autocorrect="off"
                        autocomplete="off"
                        v-model="item.keyword"
                        type="text"
                        id="label"
                        name="label"
                        :placeholder="'Label ' + (index + 1)"
                        v-validate="'required'"
                      />
                    </div>
                    <div class="col-1 align-self-center">
                      <button
                        class="text-danger mt-1"
                        v-if="isRemove || labels.keyword"
                        @click="removeData(index)"
                      >
                        <span class="material-icons-outlined"> clear </span>
                      </button>
  
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('label')"
                        >{{ errors.first("label") }}</span
                      >
                    </div>
                  </div>
                </div>
              </div> -->
  
              <!-- <div class="card-text my-1">
                <button class="btn btn-primary mt-2" @click="AddData">
                  Add Labels
                </button>
              </div> -->
            </div>
          </div>
        </div>
      </div>
  
      <div class="card border-0 shadow mt-3 p-3">
        <h5 class="mb-3 font-weight-bold td-underline">Law Features Credits</h5>
        <div class="row">
          <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <div class="card-text my-1">
              <span for="fname" class="font-weight-bold my-2"
                >Case Law Search</span
              >
              <small style="display: block" class="font-12"
                >Credits: Case Law Search</small
              >
              <!-- <span
                id="tooltip-targetAC-1"
                class="span_badge material-icons-outlined cursor-pointer"
                >help_outline</span
              >
              <b-tooltip
                target="tooltip-targetAC-1"
                triggers="hover"
                placement="righttop"
                variant="secondary"
                id="tooltip"
              >
                <span class="text-start">Case Law Search</span>
              </b-tooltip> -->
            </div>
  
            <div class="card-text my-1">
              <b-form-input
                autocorrect="off"
                autocomplete="off"
                v-model="statement"
                type="text"
                id="case_law"
                name="case_law"
                v-validate="'required'"
              />
              <span class="text-danger text-sm" v-show="errors.has('case_law')">{{
                errors.first("case_law")
              }}</span>
            </div>
  
            <div class="card-text my-1 mt-3">
              <span for="fname" class="font-weight-bold my-2"
                >Jurisdiction Summary</span
              >
              <small style="display: block" class="font-12"
                >Credits: Case Law Search - Case Summary</small
              >
              <!-- <span
                id="tooltip-targetAC-2"
                class="span_badge material-icons-outlined cursor-pointer"
                >help_outline</span
              >
              <b-tooltip
                target="tooltip-targetAC-2"
                triggers="hover"
                placement="righttop"
                variant="secondary"
                id="tooltip"
              >
                <span class="text-start">Case Law Search - Summary</span>
              </b-tooltip> -->
            </div>
  
            <div class="card-text my-1">
              <b-form-input
                autocorrect="off"
                autocomplete="off"
                v-model="jurisdiction_summarizing"
                type="text"
                id="jurisdiction_summarize"
                name="jurisdiction_summarize"
                v-validate="'required'"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('jurisdiction_summarize')"
                >{{ errors.first("jurisdiction_summarize") }}</span
              >
            </div>
  
            <div class="card-text my-1 mt-3">
              <span for="fname" class="font-weight-bold my-2"
                >Legislation Search</span
              >
              <small style="display: block" class="font-12"
                >Credits: Legislation Search</small
              >
              <!-- <span
                id="tooltip-targetAC-3"
                class="span_badge material-icons-outlined cursor-pointer"
                >help_outline</span
              >
              <b-tooltip
                target="tooltip-targetAC-3"
                triggers="hover"
                placement="righttop"
                variant="secondary"
                id="tooltip"
              >
                <span class="text-start">Legislation Search</span>
              </b-tooltip> -->
            </div>
  
            <div class="card-text my-1">
              <b-form-input
                autocorrect="off"
                autocomplete="off"
                v-model="legislation"
                type="text"
                id="legislation"
                name="legislation"
                v-validate="'required'"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('legislation')"
                >{{ errors.first("legislation") }}</span
              >
            </div>
  
            <div class="card-text my-1 mt-3">
              <span for="fname" class="font-weight-bold my-2"
                >Legislation Summary</span
              >
              <small style="display: block" class="font-12"
                >Credits: Legislation Summary</small
              >
              <!-- <span
                id="tooltip-targetAC-4"
                class="span_badge material-icons-outlined cursor-pointer"
                >help_outline</span
              >
              <b-tooltip
                target="tooltip-targetAC-4"
                triggers="hover"
                placement="righttop"
                variant="secondary"
                id="tooltip"
              >
                <span class="text-start">Legislation Summary</span>
              </b-tooltip> -->
            </div>
  
            <div class="card-text my-1">
              <b-form-input
                autocorrect="off"
                autocomplete="off"
                v-model="legislation_summarizing"
                type="text"
                id="legislation_summarizing"
                name="legislation_summarizing"
                v-validate="'required'"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('legislation_summarizing')"
                >{{ errors.first("legislation_summarizing") }}</span
              >
            </div>
  
            <div class="card-text my-1 mt-3">
              <span for="fname" class="font-weight-bold my-2"
                >Case Law Summarizer</span
              >
              <small style="display: block" class="font-12"
                >Credits: Case Law Summarizer</small
              >
              <!-- <span
                id="tooltip-targetAC-5"
                class="span_badge material-icons-outlined cursor-pointer"
                >help_outline</span
              >
              <b-tooltip
                target="tooltip-targetAC-5"
                triggers="hover"
                placement="righttop"
                variant="secondary"
                id="tooltip"
              >
                <span class="text-start">Case Law Summarizer</span>
              </b-tooltip> -->
            </div>
  
            <div class="card-text my-1">
              <b-form-input
                autocorrect="off"
                autocomplete="off"
                v-model="case_summarize"
                type="text"
                id="case_summarize"
                name="case_summarize"
                v-validate="'required'"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('case_summarize')"
                >{{ errors.first("case_summarize") }}</span
              >
            </div>
  
            <div class="card-text my-1 mt-3">
              <span for="fname" class="font-weight-bold my-2"
                >Contract Review</span
              >
              <small style="display: block" class="font-12"
                >Credits: Contract Review</small
              >
              <!-- <span
                id="tooltip-targetAC-6"
                class="span_badge material-icons-outlined cursor-pointer"
                >help_outline</span
              >
              <b-tooltip
                target="tooltip-targetAC-6"
                triggers="hover"
                placement="righttop"
                variant="secondary"
                id="tooltip"
              >
                <span class="text-start">Contract Review</span>
              </b-tooltip> -->
            </div>
  
            <div class="card-text my-1">
              <b-form-input
                autocorrect="off"
                autocomplete="off"
                v-model="contract_review"
                type="text"
                id="contract_review"
                name="contract_review"
                v-validate="'required'"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('contract_review')"
                >{{ errors.first("contract_review") }}</span
              >
            </div>
          </div>
  
          <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <div class="card-text my-1">
              <span for="fname" class="font-weight-bold my-2"
                >Contract Improvement</span
              >
              <small style="display: block" class="font-12"
                >Credits: Contract Improvement</small
              >
              <!-- <span
                id="tooltip-targetAC-7"
                class="span_badge material-icons-outlined cursor-pointer"
                >help_outline</span
              >
              <b-tooltip
                target="tooltip-targetAC-7"
                triggers="hover"
                placement="righttop"
                variant="secondary"
                id="tooltip"
              >
                <span class="text-start">Contract Improvement</span>
              </b-tooltip> -->
            </div>
  
            <div class="card-text my-1">
              <b-form-input
                autocorrect="off"
                autocomplete="off"
                v-model="contract_improvments"
                type="text"
                id="contract_improvments"
                name="contract_improvments"
                v-validate="'required'"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('contract_improvments')"
                >{{ errors.first("contract_improvments") }}</span
              >
            </div>
  
            <div class="card-text my-1 mt-3">
              <span for="fname" class="font-weight-bold my-2">LawBot</span>
              <small style="display: block" class="font-12"
                >Credits: All LawBots</small
              >
              <!-- <span
                id="tooltip-targetAC-8"
                class="span_badge material-icons-outlined cursor-pointer"
                >help_outline</span
              >
              <b-tooltip
                target="tooltip-targetAC-8"
                triggers="hover"
                placement="righttop"
                variant="secondary"
                id="tooltip"
              >
                <span class="text-start">All LawBots</span>
              </b-tooltip> -->
            </div>
  
            <div class="card-text my-1">
              <b-form-input
                autocorrect="off"
                autocomplete="off"
                v-model="qbot_questions"
                type="text"
                id="qbot_questions"
                name="qbot_questions"
                v-validate="'required'"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('qbot_questions')"
                >{{ errors.first("qbot_questions") }}</span
              >
            </div>
  
            <div class="card-text my-1 mt-3">
              <span for="fname" class="font-weight-bold my-2">Legal Writer</span>
              <small style="display: block" class="font-12"
                >Credits: Legal Writer</small
              >
              <!-- <span
                id="tooltip-targetAC-9"
                class="span_badge material-icons-outlined cursor-pointer"
                >help_outline</span
              >
              <b-tooltip
                target="tooltip-targetAC-9"
                triggers="hover"
                placement="righttop"
                variant="secondary"
                id="tooltip"
              >
                <span class="text-start">Legal Writer</span>
              </b-tooltip> -->
            </div>
  
            <div class="card-text my-1">
              <b-form-input
                autocorrect="off"
                autocomplete="off"
                v-model="lod"
                type="text"
                id="lod"
                name="lod"
                v-validate="'required'"
              />
              <span class="text-danger text-sm" v-show="errors.has('lod')">{{
                errors.first("lod")
              }}</span>
            </div>
  
            <div class="card-text my-1 mt-3">
              <span for="fname" class="font-weight-bold my-2"
                >Legal Writer Summarizer</span
              >
              <small style="display: block" class="font-12"
                >Credits: Legal Writer - Summary</small
              >
              <!-- <span
                id="tooltip-targetAC-10"
                class="span_badge material-icons-outlined cursor-pointer"
                >help_outline</span
              >
              <b-tooltip
                target="tooltip-targetAC-10"
                triggers="hover"
                placement="righttop"
                variant="secondary"
                id="tooltip"
              >
                <span class="text-start">Legal Writer - Summary</span>
              </b-tooltip> -->
            </div>
  
            <div class="card-text my-1">
              <b-form-input
                autocorrect="off"
                autocomplete="off"
                v-model="summarize_lod"
                type="text"
                id="summarize_lod"
                name="summarize_lod"
                v-validate="'required'"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('summarize_lod')"
                >{{ errors.first("summarize_lod") }}</span
              >
            </div>
  
            <div class="card-text my-1 mt-3">
              <span for="fname" class="font-weight-bold my-2"
                >Legal Research</span
              >
              <small style="display: block" class="font-12"
                >Credits: Legal Research</small
              >
              <!-- <span
                id="tooltip-targetAC-11"
                class="span_badge material-icons-outlined cursor-pointer"
                >help_outline</span
              >
              <b-tooltip
                target="tooltip-targetAC-11"
                triggers="hover"
                placement="righttop"
                variant="secondary"
                id="tooltip"
              >
                <span class="text-start">Legal Research</span>
              </b-tooltip> -->
            </div>
  
            <div class="card-text my-1">
              <b-form-input
                autocorrect="off"
                autocomplete="off"
                v-model="legal_argument"
                type="text"
                id="legal_argument"
                name="legal_argument"
                v-validate="'required'"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('legal_argument')"
                >{{ errors.first("legal_argument") }}</span
              >
            </div>
  
            <div class="card-text my-1 mt-3">
              <span for="fname" class="font-weight-bold my-2"
                >Legal Research Document Upload</span
              >
              <small style="display: block" class="font-12"
                >Credits: Legal Research - Document Upload</small
              >
              <!-- <span
                id="tooltip-targetAC-12"
                class="span_badge material-icons-outlined cursor-pointer"
                >help_outline</span
              >
              <b-tooltip
                target="tooltip-targetAC-12"
                triggers="hover"
                placement="righttop"
                variant="secondary"
                id="tooltip"
              >
                <span class="text-start">Document Upload</span>
              </b-tooltip> -->
            </div>
  
            <div class="card-text my-1">
              <b-form-input
                autocorrect="off"
                autocomplete="off"
                v-model="legal_argument_documents"
                type="text"
                id="legal_argument_documents"
                name="legal_argument_documents"
                v-validate="'required'"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('legal_argument_documents')"
                >{{ errors.first("legal_argument_documents") }}</span
              >
            </div>
          </div>
  
          <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <div class="card-text my-1">
              <span for="fname" class="font-weight-bold my-2"
                >Legal Research Case Summarizer</span
              >
              <small style="display: block" class="font-12"
                >Credits: Legal Research - Case Summarize</small
              >
              <!-- <span
                id="tooltip-targetAC-13"
                class="span_badge material-icons-outlined cursor-pointer"
                >help_outline</span
              >
              <b-tooltip
                target="tooltip-targetAC-13"
                triggers="hover"
                placement="righttop"
                variant="secondary"
                id="tooltip"
              >
                <span class="text-start">Legal Research - Case Summarize</span>
              </b-tooltip> -->
            </div>
  
            <div class="card-text my-1">
              <b-form-input
                autocorrect="off"
                autocomplete="off"
                v-model="legal_argument_summarize_discussion_case"
                type="text"
                id="legal_argument_summarize_discussion_case"
                name="legal_argument_summarize_discussion_case"
                v-validate="'required'"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('legal_argument_summarize_discussion_case')"
                >{{
                  errors.first("legal_argument_summarize_discussion_case")
                }}</span
              >
            </div>
  
            <div class="card-text my-1 mt-3">
              <span for="fname" class="font-weight-bold my-2"
                >Legal Research Document Summarizer</span
              >
              <small style="display: block" class="font-12"
                >Credits: Legal Research - Document Summarize</small
              >
              <!-- <span
                id="tooltip-targetAC-14"
                class="span_badge material-icons-outlined cursor-pointer"
                >help_outline</span
              >
              <b-tooltip
                target="tooltip-targetAC-14"
                triggers="hover"
                placement="righttop"
                variant="secondary"
                id="tooltip"
              >
                <span class="text-start">Legal Research - Doc Summarize</span>
              </b-tooltip> -->
            </div>
  
            <div class="card-text my-1">
              <b-form-input
                autocorrect="off"
                autocomplete="off"
                v-model="legal_argument_summarize_document"
                type="text"
                id="legal_argument_summarize_document"
                name="legal_argument_summarize_document"
                v-validate="'required'"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('legal_argument_summarize_document')"
                >{{ errors.first("legal_argument_summarize_document") }}</span
              >
            </div>
  
            <div class="card-text my-1 mt-3">
              <span for="fname" class="font-weight-bold my-2"
                >Legal AI Pre-Trial Copilot
              </span>
              <small style="display: block" class="font-12"
                >Credits: Pre-Trial</small
              >
              <!-- <span
                id="tooltip-targetAC-14"
                class="span_badge material-icons-outlined cursor-pointer"
                >help_outline</span
              >
              <b-tooltip
                target="tooltip-targetAC-14"
                triggers="hover"
                placement="righttop"
                variant="secondary"
                id="tooltip"
              >
                <span class="text-start">Legal Research - Doc Summarize</span>
              </b-tooltip> -->
            </div>
  
            <div class="card-text my-1">
              <b-form-input
                autocorrect="off"
                autocomplete="off"
                v-model="les_pretrial"
                type="text"
                id="les_pretrial"
                name="les_pretrial"
                v-validate="'required'"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('les_pretrial')"
                >{{ errors.first("les_pretrial") }}</span
              >
            </div>
  
            <div class="card-text my-1 mt-3">
              <span for="fname" class="font-weight-bold my-2"
                >Legal AI Trial Copilot
              </span>
              <small style="display: block" class="font-12">Credits: Trial</small>
              <!-- <span
                id="tooltip-targetAC-14"
                class="span_badge material-icons-outlined cursor-pointer"
                >help_outline</span
              >
              <b-tooltip
                target="tooltip-targetAC-14"
                triggers="hover"
                placement="righttop"
                variant="secondary"
                id="tooltip"
              >
                <span class="text-start">Legal Research - Doc Summarize</span>
              </b-tooltip> -->
            </div>
  
            <div class="card-text my-1">
              <b-form-input
                autocorrect="off"
                autocomplete="off"
                v-model="les_trial"
                type="text"
                id="les_trial"
                name="les_trial"
                v-validate="'required'"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('les_trial')"
                >{{ errors.first("les_trial") }}</span
              >
            </div>
          </div>
        </div>
      </div>
  
      <div class="card border-0 shadow mt-3 p-3">
        <h5 class="mb-3 font-weight-bold td-underline">Project & Plan</h5>
  
        <div class="row">
          <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <div class="card-text my-1">
              <span for="fname" class="font-weight-bold my-2"
                >Project Credits</span
              >
            </div>
  
            <div class="card-text my-1">
              <b-form-input
                autocorrect="off"
                autocomplete="off"
                v-model="domains_quota"
                type="text"
                id="domains_quota"
                name="project credits"
                v-validate="'required|min_value:1'"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('project credits')"
                >{{ errors.first("project credits") }}</span
              >
            </div>
          </div>
          <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <div class="card-text my-1">
              <span for="fname" class="font-weight-bold my-2">Monthly Price</span>
            </div>
  
            <div class="card-text my-1">
              <b-form-input
                autocorrect="off"
                autocomplete="off"
                v-model="monthly_renew_price"
                type="text"
                id="monthly_renew_price"
                name="monthly_renew_price"
                v-validate="'required'"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('monthly_renew_price')"
                >{{ errors.first("monthly_renew_price") }}</span
              >
            </div>
          </div>
          <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <div class="card-text my-1">
              <span for="fname" class="font-weight-bold my-2">Annual Price</span>
            </div>
  
            <div class="card-text my-1">
              <b-form-input
                autocorrect="off"
                autocomplete="off"
                v-model="annually_renew_price"
                type="text"
                id="annually_renew_price"
                name="annually_renew_price"
                v-validate="'required'"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('annually_renew_price')"
                >{{ errors.first("annually_renew_price") }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </template>
  <script>
  /* eslint-disable */
//   import Settings from "@/store/Settings.js";
  import Settings from "../../store/Settings.js";
  import Multiselect from "vue-multiselect";
  import activeTab from "./activeTab.vue";
  
  export default {
    components: {
      Multiselect,
      activeTab,
    },
    data() {
      return {
        isDropdownOpen: false,
        manager_name: "", // Initialize with an empty string
        country: "", // Initialize with an empty string
        manager_email: "", // Initialize with an empty string
        expiry_date: null, // Initialize with null for the date (since it's a date picker)
        extra_info: {
          contact_number: "",
          address_1: "",
          address_2: "",
          address_3: "",
          company_name: "",
        },
        industry: "", // Initialize with an empty string
        manager_password: "", // Initialize with an empty string
        state: "", // Initialize with an empty string
        website: "", // Initialize with an empty string
        select: [], // Initialize as an empty array (since it's checkboxes)
        itemsList: [],
        labelsList: [
          "CEO",
          "Partner",
          "Internal Staff",
          "Beta User",
          "Testing Account",
          "User",
        ],
        labels: [{ keyword: "" }], // Initialize with an array containing an empty label object
        count_campaigns: "", // Initialize with an empty string
        users_quota: "", // Initialize with an empty string
        website_scanner: "", // Initialize with an empty string
        paragraph_content_generation: "", // Initialize with an empty string
        indexability: "", // Initialize with an empty string
        page_speed: "", // Initialize with an empty string
        selectContent: "", // Initialize with an empty string (since it's a select)
        keywords_quota: "", // Initialize with an empty string
        nex_automate: "", // Initialize with an empty string
        keyword_query: "", // Initialize with an empty string
        domains_quota: 1, // Initialize with an empty string
        monthly_renew_price: 0, // Initialize with an empty string
        annually_renew_price: 0, // Initialize with an empty string
        jurisdiction_summarizing: 0,
        statement: 0,
        case_summarize: 0,
        contract_review: 0,
        legal_argument: 0,
        lod: 0,
        summarize_lod: 0,
        qbot_questions: 0,
        contract_improvments: 0,
        legal_argument_documents: 0,
        legal_argument_summarize_discussion_case: 0,
        legal_argument_summarize_document: 0,
        legislation: 0,
        legislation_summarizing: 0,
        les_pretrial: 0,
        les_trial: 0,
        isRemove: false,
        activeTab: "clientTable",
      };
    },
    computed: {
      minDate() {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0); // Set time to the beginning of the day
        return currentDate;
      },
    },
    methods: {
      // AddData() {
      //   this.labels.push({ keyword: "" });
      //   // this.isRemove = true;
      // },
      // removeData(index) {
      //   this.labels.splice(index, 1);
  
      //   // if (this.labels.length == 1) {
      //   //   this.isRemove = false;
      //   // }
      // },

      back(){
        this.$router.go(-1);
      },
      onDropdownOpen() {
        this.isDropdownOpen = true;
      },
  
      onDropdownClose() {
        this.isDropdownOpen = false;
      },
  
      AddData(selectedItem) {
        this.itemList.push(selectedItem);
      },
  
      removeData(index) {
        this.itemList.splice(index, 1);
      },
  
      formatDate(date) {
        let d = new Date(date),
          month = `${d.getMonth() + 1}`,
          day = `${d.getDate()}`,
          year = d.getFullYear();
  
        if (month.length < 2) month = `0${month}`;
        if (day.length < 2) day = `0${day}`;
  
        return [year, month, day].join("-");
      },
  
      addclient() {
        // console.log(this.extra_info)
        // Implement the logic to add the SaaS client here
        // You can access the form data using this.manager_name, this.company_name, etc.
        // Use these values to send a request to your server or perform any other actions.
  
        // const arr = [];
        // this.labels.forEach((element) => {
        //   arr.push(element.keyword);
        // });
  
        // console.log(this.itemsList);
  
        this.$validator.validateAll().then((result) => {
          if (result) {
            var obj = {
              expiry_date: this.formatDate(this.expiry_date),
              manager_name: this.manager_name,
              manager_email: this.manager_email,
              manager_password: this.manager_password,
              domains_quota: 1,
              monthly_renew_price: this.monthly_renew_price,
              annually_renew_price: this.annually_renew_price,
              labels: this.itemsList,
              extra_info: {
                contact_number: this.extra_info.contact_number,
                address:
                  this.extra_info.address_1 +
                  "\n" +
                  this.extra_info.address_2 +
                  "\n" +
                  this.extra_info.address_3,
                company_name: this.extra_info.company_name,
              },
              jurisdiction_summarizing: this.jurisdiction_summarizing,
              statement: this.statement,
              case_summarize: this.case_summarize,
              contract_review: this.contract_review,
              legal_argument: this.legal_argument,
              lod: this.lod,
              summarize_lod: this.summarize_lod,
              qbot_questions: this.qbot_questions,
              contract_improvments: this.contract_improvments,
              legal_argument_documents: this.legal_argument_documents,
              legal_argument_summarize_discussion_case:
                this.legal_argument_summarize_discussion_case,
              legal_argument_summarize_document:
                this.legal_argument_summarize_document,
              les: this.les_pretrial,
              les_trial: this.les_trial,
              legislation_summarizing: this.legislation_summarizing,
              legislation: this.legislation,
            };
  
            // console.log("Obj:", obj);
  
            Settings.addClient(obj)
              .then((response) => {
                this.$toast.success(response.data.message);
  
                this.$router.push({
                  name: "Clients",
                });
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            this.$toast.error("Please fill in all the information");
          }
        });
      },
    },
  };
  </script>
  
  <style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
  
  <style scoped>
  .container_margin {
    padding: 2rem;
  }
  
  .input-address {
    margin: 1rem 0rem;
  }
  
  .td-underline {
    text-decoration: underline;
  }
  
  .span_badge {
    border: none;
    color: black;
    border-radius: 20px;
    font-size: 17px;
    padding: 0.1rem 0.2rem;
  }

  .add-button{
  background-color: var(--primary);
  color: #fff;
  padding: 6px 80px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.add-button:hover{
  background: var(--Primary-Dark-Blue, #000046);
}
  
  /* @media only screen and (max-width: 1024px) {
    #editClient {
      padding-top: 5px !important;
      padding-bottom: 53px !important;
      padding-left: 5px !important;
      padding-right: 5px !important;
    }
  } */
  </style>
  